import Keycloak from "keycloak-js";

const keycloak = new Keycloak({
  url: process.env.REACT_APP_KEYCLOAK_AUTH_SERVER_URL,
  realm: process.env.REACT_APP_KEYCLOAK_REALM,
  clientId: process.env.REACT_APP_KEYCLOAK_RESOURCE,
});

const keycloakObject = {
  initKeycloak: async () => {
    try {
      // const authenticated = await keycloak 
      await keycloak
        .init({ onLoad: "login-required", checkLoginIframe: false })
        .then((authenticated) => {
          console.log(authenticated ? "user authenticated" : "user not authenticated");
          localStorage.setItem('UserName', keycloak.tokenParsed.email);
          localStorage.setItem('Token', keycloak.token);
        })
        .catch(() => {
          console.log("failed to initialize");
        });
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log(`Error: ${error}`);
    }
  },


  doLogin: () => {keycloak.login()},
  doLogout: () => {
      keycloak.logout();
      localStorage.removeItem('Token');
      localStorage.removeItem('UserName');
      localStorage.removeItem('UserId');
      localStorage.removeItem('emailUsuario');
      localStorage.removeItem('apellidoUsuario');
      localStorage.removeItem('nombreUsuario');
      localStorage.removeItem('autorizado');
      localStorage.removeItem('fotoUsuario');    
    },
  getToken: () => (keycloak.token ? keycloak.token: ""),
  getUserId: () => (keycloak.subject ? keycloak.subject : ""),
  getInstance: () => keycloak,
  isLoggedIn: () => !!keycloakObject.getToken(),
  updateToken: (successCallback) =>
    keycloak.updateToken(5).then(successCallback).catch(keycloakObject.doLogin),
  getTokenParsed: () => keycloak.tokenParsed,
};

export default keycloakObject;
