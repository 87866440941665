import {
	Box,
	Button,
	Checkbox,
	Dialog,
	DialogContent,
	DialogTitle,
	FormControlLabel,
	IconButton,
	LinearProgress,
	Radio,
	RadioGroup,
	TextField,
	Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import SaveIcon from "@mui/icons-material/Save";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import { useEffect, useState } from "react";
import { saveTipoVinculo } from "../../services/TipoVinculoService";
import darkTheme from "../../layout/DarkTheme";


const initialState = {
	descripcion: "",
	descripcionCorta: "",
	personaFisica: false,
	personaJuridica: false,
	sectorActor: false,
	sectorActorComo: "",
	sectorDemandado: false,
	sectorDemandadoComo: "",
	sectorOtrosConPatrocinio: false,
	sectorOtrosConPatrocinioComo: "",
	sectorOtrosSinPatrocinio: false,
	sectorOtrosSinPatrocinioProceso: false,
	observaciones: "",
};

const itemGender = "M";
const nameItemText = "Tipo Vinculo";

const actorComoOptions = [
  { value: 'actor', label: 'Como Actor' },
  { value: 'patrocinante', label: 'Como Patrocinante' },
	{ value: 'representante-legal', label: 'Como Representante Legal' }
];

const demandadoComoOptions = [
	{ value: 'demandado', label: 'Como Demandado' },
  { value: 'patrocinante', label: 'Como Patrocinante' },
	{ value: 'representante-legal', label: 'Como Representante Legal' }
];

const otrosConPatrocinioComoOptions = [
	{ value: 'patrocinante', label: 'Como Patrocinante' },
	{ value: 'otro', label: 'Como Otro' }
];

const otrosSinPatrocinioProcesoOptions = [
	{ value: true, label: 'Interviene en el proceso' },
	{ value: false, label: 'NO interviene en el proceso' }
];

//
// ##################################################################################################################### 
//
const TipoVinculoDialog = ({
	onSave,
	onClose,
	itemCrud,
	actionCrud,
	setMessage,
	setShowMessage,
}) => {
	const [itemCrudLocal, setItemCrudLocal] = useState(initialState);
	const [isDoingSomething, setIsDoingSomething] = useState(false);

	useEffect(() => {
		if (actionCrud === "create") {
			setItemCrudLocal(initialState);
		} else {
			setItemCrudLocal(itemCrud);
		}
	// eslint-disable-next-line react-hooks/exhaustive-deps 
	}, []);

	const handleCloseDialog = () => {
		onClose();
	};

	// const handleChange = (e) => {
	// 	setItemCrudLocal({
	// 		...itemCrudLocal,
	// 		[e.target.name]: e.target.value,
	// 	});
	// };



  const handleChange = (e) => {
    const { name, type, checked, value } = e.target;
    setItemCrudLocal({
      ...itemCrudLocal,
      [name]: type === 'checkbox' ? checked : value,
    });
  };


	const handleSave = () => {

		if (actionCrud === "create" || actionCrud === "update") {

			if (itemCrudLocal.descripcion.trim() === "") {
				setMessage({
					severity: "error",
					messageText: "Debes ingresar una descripción",
				});
				setShowMessage(true);
				return;
			}
			
			if (!itemCrudLocal.personaFisica && !itemCrudLocal.personaJuridica) {
				setMessage({
					severity: "error",
					messageText: "Debes seleccionar al menos un tipo de persona",
				});
				setShowMessage(true);
				return;
			}
			
			if (!itemCrudLocal.sectorActor && !itemCrudLocal.sectorDemandado && !itemCrudLocal.sectorOtrosConPatrocinio && !itemCrudLocal.sectorOtrosSinPatrocinio) {
				setMessage({
					severity: "error",
					messageText: "Debes seleccionar al menos un sector en que el tipo de vínculo sea válido",
				});
				setShowMessage(true);
				return;
			}
		}

		if (actionCrud === "create" ) {
			if (itemCrudLocal.descripcionCorta.trim() === "") {
				setMessage({
					severity: "error",
					messageText: "Debes ingresar una descripción corta.",
				});
				setShowMessage(true);
				return;
			}
		}

		save();
	};

	const save = async () => {
		let sectorOtrosSinPatrocinioProceso = false;
		if (itemCrudLocal.sectorOtrosSinPatrocinio==="true") {
			sectorOtrosSinPatrocinioProceso = true;
		}
		let _itemCrud = {
			descripcion: itemCrudLocal.descripcion,
			personaFisica: itemCrudLocal.personaFisica,
			personaJuridica: itemCrudLocal.personaJuridica,
			sectorActor: itemCrudLocal.sectorActor,
			sectorActorComo: itemCrudLocal.sectorActorComo,
			sectorDemandado: itemCrudLocal.sectorDemandado,
			sectorDemandadoComo: itemCrudLocal.sectorDemandadoComo,
			sectorOtrosConPatrocinio: itemCrudLocal.sectorOtrosConPatrocinio,
			sectorOtrosConPatrocinioComo: itemCrudLocal.sectorOtrosConPatrocinioComo,
			sectorOtrosSinPatrocinio: itemCrudLocal.sectorOtrosSinPatrocinio,
			sectorOtrosSinPatrocinioProceso: sectorOtrosSinPatrocinioProceso,
			observaciones: itemCrudLocal.observaciones,
			usernameUsuarioActualiza: localStorage.getItem("emailUsuario"),
		};
		if (actionCrud !== "create") {
			_itemCrud.id = itemCrudLocal.id;
		}
		if (actionCrud === "create") {
			_itemCrud.descripcionCorta = itemCrudLocal.descripcionCorta;
		}

		setIsDoingSomething(true);
		const response = await saveTipoVinculo(actionCrud, _itemCrud);
		setIsDoingSomething(false);

		if (response.ok) {
			onSave();
    }

    setMessage({
		messageText: response.messageText,
		severity: response.messageSeverity,
    });
    setShowMessage(true);
	};

	return (
		<>
			<Dialog
				open={true}
				onClose={handleCloseDialog}
				maxWidth="md"
        fullWidth={true}
				scroll="paper"
				aria-labelledby="form-dialog-title"
			>
			<DialogTitle id="form-dialog-title"
					textAlign={{ xs: "center", sm: "center" }}			
					style={{ background: darkTheme.palette.primary.dark, color: darkTheme.palette.primary.text}}
				>
					{actionCrud === "create"
						? itemGender === "M"
							? "Nuevo"
							: "Nueva"
						: actionCrud === "update"
						? "Editando"
						: actionCrud === "enable"
						? "Habilitando"
						: actionCrud === "read"
						? "Detalle"
						: "Deshabilitando"}{" "}
					{nameItemText}
					<IconButton
						aria-label="close"
						onClick={handleCloseDialog}
						sx={{
							position: "absolute",
							right: 8,
							top: 8,
							color: (theme) => theme.palette.grey[500],
						}}
					>
						<CloseIcon />
					</IconButton>
				</DialogTitle>

				{isDoingSomething && <LinearProgress />}

				<DialogContent dividers>
					<Box sx={{ m: 1, p: 1 }}>
						<TextField
							variant="outlined"
							size="small"
							name="descripcion"
							label="Descripción"
							value={itemCrudLocal.descripcion}
							onChange={handleChange}
							disabled={actionCrud === "disable" || actionCrud === "enable" || actionCrud === "read"}
							sx={{ width: "100%", pb: 1 }}
							inputProps={{ maxLength: 80 }}
						/>

						<TextField
							variant="outlined"
							size="small"
							name="descripcionCorta"
							label="Descripción corta"
							value={itemCrudLocal.descripcionCorta}
							onChange={handleChange}
							disabled={actionCrud === "disable" || actionCrud === "update" || actionCrud === "enable" || actionCrud === "read"}
							sx={{ width: "100%", pb: 1 }}
							inputProps={{ maxLength: 80 }}
						/>

						<Typography variant="h8" component="div" gutterBottom style={{ marginBottom: 0, fontWeight: 'bold' }}>
									Tipo de Persona:
						</Typography>
						<Box display="flex" alignItems="center">
							<Box display="flex" alignItems="center" mr={2}>
								<Checkbox
									name="personaFisica"
									checked={itemCrudLocal.personaFisica}
									onChange={handleChange}
									disabled={actionCrud === "disable" || actionCrud === "enable" || actionCrud === "read"}
								/>
								<Typography variant="h8" component="div" gutterBottom style={{ marginBottom: 0 }}>
									Física
								</Typography>
							</Box>

							<Box display="flex" alignItems="center">
								<Checkbox
									name="personaJuridica"
									checked={itemCrudLocal.personaJuridica}
									onChange={handleChange}
									disabled={actionCrud === "disable" || actionCrud === "enable" || actionCrud === "read"}
								/>
								<Typography variant="h8" component="div" gutterBottom style={{ marginBottom: 0 }}>
									Jurídica
								</Typography>
							</Box>
						</Box>

						<Typography variant="h8" component="div" gutterBottom style={{ marginBottom: 0, fontWeight: 'bold' }}>
									Válido en sector:
						</Typography>

						<Box display="flex" alignItems="center">
							<Box display="flex" alignItems="center" mr={2}>
								<Checkbox
									name="sectorActor"
									checked={itemCrudLocal.sectorActor}
									onChange={handleChange}
									disabled={actionCrud === "disable" || actionCrud === "enable" || actionCrud === "read"}
								/>
								<Typography variant="h8" component="div" gutterBottom style={{ marginBottom: 0 }}>
									Actores
								</Typography>
							</Box>
						</Box>
												
					{itemCrudLocal.sectorActor && (
						<Box display="flex" alignItems="center" >
							<Box display="flex" alignItems="center" ml={4}>
								<RadioGroup
									row
									name="sectorActorComo"
									value={itemCrudLocal.sectorActorComo}
									onChange={handleChange}
								>
									{actorComoOptions.map((option) => (
										<FormControlLabel
											key={option.value}
											value={option.value}
											control={<Radio disabled={actionCrud === "disable" || actionCrud === "enable" || actionCrud === "read"} />}
											label={option.label}
										/>
									))}
								</RadioGroup>
							</Box>
						</Box>
					)}

					<Box display="flex" alignItems="center">
						<Box display="flex" alignItems="center" mr={2}>
							<Checkbox
								name="sectorDemandado"
								checked={itemCrudLocal.sectorDemandado}
								onChange={handleChange}
								disabled={actionCrud === "disable" || actionCrud === "enable" || actionCrud === "read"}
							/>
							<Typography variant="h8" component="div" gutterBottom style={{ marginBottom: 0 }}>	
								Demandados
							</Typography>
						</Box>
					</Box>

					{itemCrudLocal.sectorDemandado && (
						<Box display="flex" alignItems="center" >
							<Box display="flex" alignItems="center" ml={4}>
								<RadioGroup
									row
									name="sectorDemandadoComo"
									value={itemCrudLocal.sectorDemandadoComo}
									onChange={handleChange}
								>
									{demandadoComoOptions.map((option) => (
										<FormControlLabel
											key={option.value}
											value={option.value}
											control={<Radio disabled={actionCrud === "disable" || actionCrud === "enable" || actionCrud === "read"} />}
											label={option.label}
										/>
									))}
								</RadioGroup>
							</Box>
						</Box>
					)}
					
					<Box display="flex" alignItems="center">
						<Box display="flex" alignItems="center" mr={2}>
							<Checkbox
								name="sectorOtrosConPatrocinio"
								checked={itemCrudLocal.sectorOtrosConPatrocinio}
								onChange={handleChange}
								disabled={actionCrud === "disable" || actionCrud === "enable" || actionCrud === "read"}
							/>
							<Typography variant="h8" component="div" gutterBottom style={{ marginBottom: 0 }}>
								Otros con patrocinio
							</Typography>
						</Box>
					</Box>
					
					{itemCrudLocal.sectorOtrosConPatrocinio && (
						<Box display="flex" alignItems="center" >
							<Box display="flex" alignItems="center" ml={4}>
								<RadioGroup
									row
									name="sectorOtrosConPatrocinioComo"
									value={itemCrudLocal.sectorOtrosConPatrocinioComo}
									onChange={handleChange}
								>
									{otrosConPatrocinioComoOptions.map((option) => (
										<FormControlLabel
											key={option.value}
											value={option.value}
											control={<Radio disabled={actionCrud === "disable" || actionCrud === "enable" || actionCrud === "read"} />}
											label={option.label}
										/>
									))}
								</RadioGroup>
							</Box>
						</Box>
					)}

					<Box display="flex" alignItems="center">
						<Box display="flex" alignItems="center" mr={2}>
							<Checkbox
								name="sectorOtrosSinPatrocinio"
								checked={itemCrudLocal.sectorOtrosSinPatrocinio}
								onChange={handleChange}
								disabled={actionCrud === "disable" || actionCrud === "enable" || actionCrud === "read"}
							/>
							<Typography variant="h8" component="div" gutterBottom style={{ marginBottom: 0 }}>
								Otros sin patrocinio
							</Typography>
						</Box>
					</Box>
					
					{itemCrudLocal.sectorOtrosSinPatrocinio && (
						<Box display="flex" alignItems="center" >
							<Box display="flex" alignItems="center" ml={4}>
								<RadioGroup
									row
									name="sectorOtrosSinPatrocinioProceso"
									value={itemCrudLocal.sectorOtrosSinPatrocinioProceso}
									onChange={handleChange}
								>
									{otrosSinPatrocinioProcesoOptions.map((option) => (
										<FormControlLabel
											key={option.value}
											value={option.value}
											control={<Radio disabled={actionCrud === "disable" || actionCrud === "enable" || actionCrud === "read"} />}
											label={option.label}
										/>
									))}
								</RadioGroup>
							</Box>
						</Box>
					)}

						<TextField
							variant="outlined"
							size="small"
							name="observaciones"
							label="Observaciones"
							value={itemCrudLocal.observaciones}
							onChange={handleChange}
							disabled={actionCrud === "disable" || actionCrud === "enable" || actionCrud === "read"}
							sx={{ width: "100%", pb: 1 }}
							inputProps={{ maxLength: 80 }}
						/>

						{actionCrud !== "create" && (
							<Box
								sx={{
									p: 1,
									mt: 1,
									color: "SlateGrey",
									border: "0px solid DarkGray",
									borderRadius: "3px",
								}}
							>
								<Typography variant="h8" component="div" gutterBottom>
									Creado: {itemCrudLocal.fechaCreacion}
								</Typography>

								<Typography variant="h8" component="div" gutterBottom>
									Última actualización: {itemCrudLocal.fechaActualizacion}
								</Typography>

								<Typography variant="h8" component="div" gutterBottom>
									Último usuario que actualizó: {itemCrudLocal.nombreUsuario}{" "}
									{itemCrudLocal.apellidoUsuario} ({itemCrudLocal.username})
								</Typography>
							</Box>
						)}
					</Box>

					<Box sx={{ textAlign: "right" }}>
						<Button
							sx={{ mr: 1 }}
							variant="outlined"
							onClick={handleCloseDialog}
							startIcon={<CloseIcon />}
						>									
							Cancelar
						</Button>

						{(actionCrud !== "read") && (	
							<Button
							sx={{ mr: 1, backgroundcolor: "warning" }}
							variant="contained"
							onClick={handleSave}
							startIcon={
								actionCrud === "disable" ? (
									<RemoveCircleOutlineIcon />
								) : actionCrud === "enable" ? (
									<AddCircleOutlineIcon />
								) : (
									<SaveIcon />
								)
							}
						>
							{actionCrud === "disable"
								? "Deshabilitar"
								: actionCrud === "enable"
								? "Habilitar"
								: "Guardar"}
						</Button>
						)}
						</Box>
				</DialogContent>
			</Dialog>
		</>
	);
};

export default TipoVinculoDialog;
