import {
	Button,
	FormControl,
	FormControlLabel,
  FormLabel,
	Grid,
	InputLabel,
	LinearProgress,
	MenuItem,
	Paper,
	Radio,
  RadioGroup,
	Select,
	ThemeProvider,
	TextField,
	Tooltip,
} from "@mui/material";
import MUIDataTable from "mui-datatables";
import IconButton from "@mui/material/IconButton";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import RemoveIcon from "@mui/icons-material/Remove";
import ZoomInIcon from '@mui/icons-material/ZoomIn';
import AddIcon from "@mui/icons-material/Add";
import SearchIcon from "@mui/icons-material/Search";
import CleaningServicesIcon from '@mui/icons-material/CleaningServices';
import { useEffect, useState } from "react";
import { getTiposVinculos, getTipoVinculo } from "../../services/TipoVinculoService.js";
import TipoVinculoDialog from "./TipoVinculoDialog.jsx";
import Message from "../../utils/Message.jsx";
import { Box } from "@mui/system";
import darkTheme from "../../layout/DarkTheme.jsx";
import React from 'react';
import {  useMenu } from "../../context/MenuContext.js";

const initialState = {
	descripcion: "",
	descripcionCorta: "",
	estado: "habilitado",
	orden: "ASC",
  ordenarPor: "descripcion",
	rows: 0,
	first: 0,
};

const estadoOptions = [
	{
		id: "todo",
		descripcion: "Todo",
	},
	{
		id: "habilitado",
		descripcion: "Habilitado",
	},
	{
		id: "deshabilitado",
		descripcion: "Deshabilitado",
	},
];

const ordenOptions = [
  {
    id: "ASC",
    descripcion: "Ascendente",
  },
  {
    id: "DESC",
    descripcion: "Descendente",
  },
];

const ordenarPorOptions = [
  {
    id: "descripcion",
    descripcion: "Tipo Vinculo",
  },
	{
    id: "descripcion_corta",
    descripcion: "Descripción Corta",
  }
];

const nameItemsText = "Tipos de Vínculos";
//
// ##################################################################################################################### 
//
const TipoVinculoTable = ({...data}) => {
	const [searchData, setSearchData] = useState(initialState);
	const [itemsCrud, setItemsCrud] = useState([]);
	const [itemCrud, setItemCrud] = useState({});
	const [actionCrud, setActionCrud] = useState("");
	const [rowsPerPage, setRowsPerPage] = useState(10);
	const [openDialog, setOpenDialog] = useState(false);
	const [message, setMessage] = useState({});
	const [showMessage, setShowMessage] = useState(false);
	const [isDoingSomething, setIsDoingSomething] = useState(false);
	const [totalRowsCount, setTotalRowsCount] = useState(0);
	const [currentPage, setCurrentPage] = useState(0);
  const { menuOpen } = useMenu();

  const tableStyles = {
    width: menuOpen ?  "80%": "95%", 
    height: "90%",
    overflow: "auto",
    p: 1,
    margin: "auto",
    position: 'fixed', 
    top: "8%", 
    left: menuOpen ? '15%' : '0%', 
    transition: 'left 0.3s ease' 
  };


	const tableColumns = [
		{
			name: "id",
			label: "ID",
			options: {
				display: "excluded",
			},
		},
		{
			name: "descripcion",
			label: "Tipo Vínculo",
			options: {
				filter: false,
				sort: false,
			},
		},
		{
			name: "descripcion_corta",
			label: "Descripción Corta",
			options: {
				filter: false,
				sort: false,
			},
		},
		{
			name: "tipo_persona",
			label: "Tipo Persona",
			options: {
				filter: false,
				sort: false,
				customBodyRender: (value, tableMeta, updateValue) => {
					const persona = itemsCrud[tableMeta.rowIndex].persona_fisica === true && itemsCrud[tableMeta.rowIndex].persona_juridica === true ? "Ambas" : itemsCrud[tableMeta.rowIndex].persona_fisica === false ? "Jurídica" : "Física";

					return `${persona}`;
				}
			},
		},
		{
			name: "valido_en_sector",
			label: "Válido en Sector (como)",
			options: {
				filter: false,
				sort: false,
				customBodyRender: (value, tableMeta, updateValue) => {
					let sector = "";
					if (itemsCrud[tableMeta.rowIndex].sector_actor === true) {
						sector = `Actores (${itemsCrud[tableMeta.rowIndex].sector_actor_como})`;
					}
					if (itemsCrud[tableMeta.rowIndex].sector_demandado === true) {
						sector = sector === "" ? `Demandados (${itemsCrud[tableMeta.rowIndex].sector_demandado_como})` : `${sector}; Demandados (${itemsCrud[tableMeta.rowIndex].sector_demandado_como})`;
					}
					if (itemsCrud[tableMeta.rowIndex].sector_otros_con_patrocinio === true) {
						sector = sector === "" ? `Otros con patrocinio (${itemsCrud[tableMeta.rowIndex].sector_otros_con_patrocinio_como})` : `${sector}; Otros con patrocinio (${itemsCrud[tableMeta.rowIndex].sector_otros_con_patrocinio_como})`;
					}
					if (itemsCrud[tableMeta.rowIndex].sector_otros_sin_patrocinio === true) {
						let sinPatrocinioProceso = itemsCrud[tableMeta.rowIndex].sector_otros_sin_patrocinio_proceso === true ? "con intervención" : "sin intervención";
						sector = sector === "" ? `Otros sin patrocinio (${itemsCrud[tableMeta.rowIndex].sector_otros_sin_patrocinio_proceso})` : `${sector}; Otros sin patrocinio (${sinPatrocinioProceso})`;
					}
					return `${sector}`;
					}
			},
		},

		{
			name: "actions",
			label: "Acciones",
			options: {
				filter: false,
				sort: false,
				empty: true,
				customBodyRenderLite: (dataIndex, rowIndex) => (
					<>
						{itemsCrud[dataIndex].habilitado && (
							<Tooltip title="Editar">
								<IconButton
									size="small"
									aria-label="update"
									onClick={() => handleClickActionCrud(itemsCrud[dataIndex], "update")}
								>
									<EditOutlinedIcon />
								</IconButton>
							</Tooltip>
						)}

						{itemsCrud[dataIndex].habilitado && (
							<Tooltip title="Deshabilitar">
								<IconButton
									size="small"
									aria-label="disable"
									onClick={() => handleClickActionCrud(itemsCrud[dataIndex], "disable")}
								>
									<RemoveIcon />
								</IconButton>
							</Tooltip>
						)}

						{!itemsCrud[dataIndex].habilitado && (
							<Tooltip title="Habilitar">
								<IconButton
									size="small"
									aria-label="enable"
									onClick={() => handleClickActionCrud(itemsCrud[dataIndex], "enable")}
								>
									<AddIcon />
								</IconButton>
							</Tooltip>
						)}
					
					{(
              <Tooltip title="Ver">
                <IconButton
                  size="small"
                  aria-label="read"
                  onClick={() => handleClickActionCrud(itemsCrud[dataIndex], "read")}
                >
                  <ZoomInIcon />
                </IconButton>
              </Tooltip>
            )}
						</>
				),
			},
		},
	];

	const tableOptions = {
		storageKey: "my-table-state",
		rowsPerPageOptions: [10, 20, 30],
		serverSide: true,
		count: totalRowsCount,
		rowsPerPage: rowsPerPage,
		onChangeRowsPerPage: (numberOfRows) => {
			setRowsPerPage(numberOfRows);
		},
		onChangePage: (currentPage) => {
			setCurrentPage(currentPage);
		},
		download: false,
		filter: false,
		print: false,
		search: false,
		viewColumns: false,
		selectableRowsHeader: false,
		selectableRows: "none",
		elevation: 1,
		textLabels: {
			body: {
				noMatch: "No se han encontrado resultados para esta búsqueda",
			},
			pagination: {
				next: "Próxima",
				previous: "Previa",
				rowsPerPage: "Filas:",
				displayRows: "de",
			},
		},
	};
	//
	// ################################################################################
	//
	const load = async () => {
		searchData.rows = rowsPerPage;
		searchData.first =  currentPage * rowsPerPage;
		searchData.sortField = searchData.ordenarPor;
		searchData.sortOrder = searchData.orden; 

		setIsDoingSomething(true);
		const response = await getTiposVinculos(searchData);
		setIsDoingSomething(false);

		if (response.ok) {
			setItemsCrud(response.data.data);
			setTotalRowsCount(response.data.count);
		} else {
			setMessage({
				messageText: response.messageText,
				severity: response.messageSeverity,
			});
			setShowMessage(true);
		}
	};



	//
	// ################################################################################
	//




	useEffect(() => {
		load();
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [currentPage, rowsPerPage]);

	const handleChange = (e) => {
		setSearchData({
			...searchData,
			[e.target.name]:
				e.target.type === "checkbox" ? e.target.checked : e.target.value,
		});
	};

	const search = () => {
		setCurrentPage(0);
		load();
	};

	const clean = () => {
		setSearchData(initialState);
		setItemsCrud([]);
	};

	const handleClickActionCrud = (itemCrud, action) => {
		let _itemCrud = {};

		if (action !== "create") {
			const loadItem = async () => {
				setIsDoingSomething(true);
				const response = await getTipoVinculo({ id: itemCrud.id });
				setIsDoingSomething(false);

				if (response.ok) {
					_itemCrud = {
						id: response.data.data.id,
						descripcion: response.data.data.descripcion,
						descripcionCorta: response.data.data.descripcion_corta,
						personaFisica: response.data.data.persona_fisica,
						personaJuridica: response.data.data.persona_juridica,
						sectorActor: response.data.data.sector_actor,
						sectorActorComo: response.data.data.sector_actor_como,
						sectorDemandado: response.data.data.sector_demandado,
						sectorDemandadoComo: response.data.data.sector_demandado_como,
						sectorOtrosConPatrocinio: response.data.data.sector_otros_con_patrocinio,
						sectorOtrosConPatrocinioComo: response.data.data.sector_otros_con_patrocinio_como,
						sectorOtrosSinPatrocinio: response.data.data.sector_otros_sin_patrocinio,
						sectorOtrosSinPatrocinioProceso: response.data.data.sector_otros_sin_patrocinio_proceso,
						observaciones: response.data.data.observaciones,
						fechaCreacion: response.data.data.fecha_creacion,
						fechaActualizacion: response.data.data.fecha_actualizacion,
						nombreUsuario: response.data.data.nombre_usuario,
						apellidoUsuario: response.data.data.apellido_usuario,
						username: response.data.data.username,
					};

					setActionCrud(action);
					setItemCrud(_itemCrud);
					setOpenDialog(true);
				} else {
					setMessage({
						severity: response.messageSeverity,
						messageText: response.messageText,
					});
					setShowMessage(true);
					return;
				}
			};
			loadItem();
		} else {
			setActionCrud(action);
			setItemCrud(_itemCrud);
			setOpenDialog(true);
		}
	};

	const handleDialogSave = () => {
		load();
		setOpenDialog(false);
	};

	const handleDialogClose = () => {
		setOpenDialog(false);
	};
	//
	// ################################################################################
	// let table = data.data.table

	return (
		<>
			<Paper
				sx={tableStyles}
				elevation={2}
			>
				<Box
					sx={{
						margin: "auto",
						width: "90%",
					}}
				>
					<Box sx={{ m: 1, p: 1 }}>
						<Grid container spacing={1}>
							<Grid item xs={12}>
								<h2>{nameItemsText}</h2>
							</Grid>
							<Grid item xs={12} md={6} lg={3}>
								<TextField
									sx={{ width: "100%", mr: 1 }}
									id="descripcion"
									name="descripcion"
									value={searchData.descripcion}
									onChange={handleChange}
									autoFocus={true}
									label="Tipo Vinculo"
									variant="outlined"
									size="small"
								/>
							</Grid>
							<Grid item xs={12} md={6} lg={3}>
								<TextField
									sx={{ width: "100%", mr: 1 }}
									id="descripcionCorta"
									name="descripcionCorta"
									value={searchData.descripcionCorta}
									onChange={handleChange}
									autoFocus={true}
									label="Descripción Corta"
									variant="outlined"
									size="small"
								/>
							</Grid>
							<Grid item xs={12} md={6} lg={3}>
								<FormControl sx={{ width: "100%" }}>
									<InputLabel id="estado-select-label">Estado</InputLabel>
									<Select
										sx={{ width: "100%", mr: 1 }}
										labelId="estado"
										label="Estado"
										id="estado"
										name="estado"
										value={searchData.estado}
										size="small"
										onChange={handleChange}
									>
										{estadoOptions.map((item) => (
											<MenuItem key={item.id} value={item.id}>
												{item.descripcion}
											</MenuItem>
										))}
									</Select>
								</FormControl>
							</Grid>
						</Grid>
					</Box>

          <Box sx={{ m: 1, p: 1 }}>
            <Grid container spacing={1}>
              <Grid item xs={12} md={3}>
                <FormControl sx={{ width: "100%" }}>
                  <InputLabel id="ordenarPor-select-label">
                    Ordenar por:
                  </InputLabel>
                  <Select
                    sx={{ width: "100%", mr: 1 }}
                    labelId="ordenarPor"
                    label="Ordenar por:"
                    id="ordenaPor"
                    name="ordenarPor"
                    value={searchData.ordenarPor}
                    size="small"
                    onChange={handleChange}
                  >
                    {ordenarPorOptions.map((item) => (
                      <MenuItem key={item.id} value={item.id}>
                        {item.descripcion}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
							<Grid item xs={12} md={0.5}>
              </Grid>
              <Grid item xs={12} md={4}>
                <Grid container spacing={1}>
                  <Grid item xs={6}>
                    <FormControl component="fieldset">
                      <FormLabel
                        component="legend"
                        sx={{
                          textAlign: "center",
                          mb: 1,
                          fontSize: "0.875rem",
                        }}
                      >
                        Orden
                      </FormLabel>
                      <RadioGroup
                        aria-label="Orden"
                        name="orden"
                        value={searchData.orden}
                        onChange={handleChange}
                        row
                      >
                        {ordenOptions
                          .slice(0, Math.ceil(ordenOptions.length / 2))
                          .map((item) => (
                            <FormControlLabel
                              key={item.id}
                              value={item.id}
                              label={item.descripcion}
                              control={<Radio />}
                            />
                          ))}
                      </RadioGroup>
                    </FormControl>
                  </Grid>
                  <Grid item xs={6}>
                    <FormControl component="fieldset">
                      <FormLabel
                        component="legend"
                        sx={{
                          textAlign: "center",
                          mb: 1,
                          fontSize: "0.875rem",
                        }}
                      >
                        &nbsp;
                      </FormLabel>
                      <RadioGroup
                        aria-label="Orden"
                        name="orden"
                        value={searchData.orden}
                        onChange={handleChange}
                        row
                      >
                        {ordenOptions
                          .slice(Math.ceil(ordenOptions.length / 2))
                          .map((item) => (
                            <FormControlLabel
                              key={item.id}
                              value={item.id}
                              label={item.descripcion}
                              control={<Radio />}
                            />
                          ))}
                      </RadioGroup>
                    </FormControl>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Box>

					<Box sx={{ m: 1, p: 1 }}>
						<Grid container spacing={1}>
							<Grid item xs={12} md={3} lg={2}>
								<Button
									sx={{ width: "100%" }}
									variant="contained"
									onClick={() => handleClickActionCrud({}, "create")}
									startIcon={<AddIcon />}
								>
									Nuevo
								</Button>
							</Grid>
							<Grid item xs={12} md={3} lg={2}>
								<Button
									sx={{ width: "100%" }}
									variant="contained"
									onClick={search}
									startIcon={<SearchIcon />}
								>
									Buscar
								</Button>
							</Grid>
							<Grid item xs={12} md={3} lg={2}>
								<Button
									sx={{ width: "100%", mr: 1 }}
									variant="contained"
									onClick={clean}
									startIcon={<CleaningServicesIcon />}
								>
									Limpiar
								</Button>
							</Grid>
						</Grid>
					</Box>

          <Box sx={{ m: 1, p: 1 }}>
          </Box>
            {isDoingSomething && <LinearProgress />} 
					<ThemeProvider theme={darkTheme}>
						<MUIDataTable columns={tableColumns} data={itemsCrud} options={tableOptions} />
					</ThemeProvider>	
				</Box>
			</Paper>

			{/* >>>> Dialog */}

			{openDialog && (
				<TipoVinculoDialog
					onSave={handleDialogSave}
					onClose={handleDialogClose}
					itemCrud={itemCrud}
					actionCrud={actionCrud}
					setMessage={setMessage}
					setShowMessage={setShowMessage}
				/>
			)}

			{/* >>>> Messages */}

			{showMessage && (
				<Message
					showMessage={showMessage}
					setShowMessage={setShowMessage}
					message={message}
				/>
			)}
		</>
	);
};

export default TipoVinculoTable;
