import { format } from "date-fns";
import { isValidDate, isValidEmail } from "../../utils/utils";
import {
  Box,
  Button,
	Dialog,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  IconButton,
  InputLabel,
  LinearProgress,
  MenuItem,
  Radio, 
  RadioGroup, 
  Select,
  TextField,
  Typography,
} from "@mui/material";
import DeleteIcon from '@mui/icons-material/Delete';
import AddAPhotoIcon from '@mui/icons-material/AddAPhoto';
import CloseIcon from "@mui/icons-material/Close";
import SaveIcon from "@mui/icons-material/Save";
import DatePicker from "@mui/lab/DatePicker";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import { useEffect, useState } from "react";
import { saveUsuario } from "../../services/UsuarioService";
import darkTheme from "../../layout/DarkTheme";


const initialState = {
  tipoDocumento: "",
  nroDocumento: "",
  sexoRegistradoAlNacer: "",
  nombre: "",
  apellido: "",
  username: "",
  legajo: "",
  matricula: "",
  cuil: "",
  email: "",
  genero: "femenino",
  fechaNacimiento: null,
  telefonoMovil: "",
  estadoUltimo: "",
  fechaEstadoUltimo: null,
  codigoExterno: "",
  origenDatos: "manual",
  fotoPerfil: "",
};

const generoOptions = [
  {
    id: "femenino",
    descripcion: "Femenino",
  },
  {
    id: "masculino",
    descripcion: "Masculino",
  },
  {
    id: "otro",
    descripcion: "Otro",
  },
];

const origenDatosOptions = [
  {
    id: "manual",
    descripcion: "Manual",
  },
  {
    id: "automatico",
    descripcion: "Automático",
  },
];

const sexoRegistradoAlNacerOptions = [
	{ value: 'M', label: 'Masculino' },
	{ value: 'F', label: 'Femenino' },
];

const itemGender = "M";
const nameItemText = "Usuario";

//
// #####################################################################################################################
//
const UsuarioDialog = ({
  onSave,
  onClose,
  itemCrud,
  actionCrud,
  setMessage,
  setShowMessage,
}) => {
  const [itemCrudLocal, setItemCrudLocal] = useState(initialState);
  const [isDoingSomething, setIsDoingSomething] = useState(false);
  const [fotoPerfil, setFotoPerfil] = useState("");

  useEffect(() => {
    if (actionCrud === "create") {
      setItemCrudLocal(initialState);
    } else {
      setItemCrudLocal(itemCrud);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    const reader = new FileReader();
    if (file) {
      reader.readAsDataURL(file);
      reader.onload = () => {
        setFotoPerfil(reader.result);
      };
    }
  };

  const handleRemovePhoto = () => {
    setFotoPerfil("");
    setMessage({
      severity: "info",
      messageText:
        "Se ha eliminado la foto de perfil. Para guardar los cambios, haga clic en el botón 'Guardar'.",
    });
    setShowMessage(true);
  };

  const handleCloseDialog = () => {
    onClose();
  };

  const handleChange = (e) => {
    setItemCrudLocal({
      ...itemCrudLocal,
      [e.target.name]: e.target.value,
    });
  };

  const handleChangeFechaNacimiento = (fechaNacimiento) => {
    setItemCrudLocal({
      ...itemCrudLocal,
      fechaNacimiento: fechaNacimiento,
    });
  };

  const handleChangeFechaEstadoUltimo = (fechaEstadoUltimo) => {
    setItemCrudLocal({
      ...itemCrudLocal,
      fechaEstadoUltimo: fechaEstadoUltimo,
    });
  };

  const handleSave = () => {
    if (actionCrud === "create" || actionCrud === "update") {
      if (
        itemCrudLocal.nombre.trim() === "" ||
        itemCrudLocal.nombre.length < 3
      ) {
        setMessage({
          severity: "error",
          messageText: "El nombre del usuario debe tener al menos 3 caracteres",
        });
        setShowMessage(true);
        return;
      }

      if (
        itemCrudLocal.apellido.trim() === "" ||
        itemCrudLocal.apellido.length < 3
      ) {
        setMessage({
          severity: "error",
          messageText:
            "El apellido del usuario debe tener al menos 3 caracteres",
        });
        setShowMessage(true);
        return;
      }

      if (!isValidEmail(itemCrudLocal.username)) {
        setMessage({
          severity: "error",
          messageText: "El username del usuario debe tener formato de e-mail",
        });
        setShowMessage(true);
        return;
      }

      if (!isValidDate(itemCrudLocal.fechaNacimiento)) {
        setMessage({
          severity: "error",
          messageText: "La fecha de nacimiento ingresada no es válida",
        });
        setShowMessage(true);
        return;
      }

      if (!isValidDate(itemCrudLocal.fechaEstadoUltimo)) {
        setMessage({
          severity: "error",
          messageText: "La fecha de último estado ingresada no es válida",
        });
        setShowMessage(true);
        return;
      }
    }
    save();
  };

  const save = async () => {
    let _itemCrud = {
      tipoDocumento: itemCrudLocal.tipoDocumento,
      nroDocumento: itemCrudLocal.nroDocumento,
      sexoRegistradoAlNacer: itemCrudLocal.sexoRegistradoAlNacer,
      nombre: itemCrudLocal.nombre,
      apellido: itemCrudLocal.apellido,
      username: itemCrudLocal.username,
      legajo: itemCrudLocal.legajo,
      matricula: itemCrudLocal.matricula,
      cuil: itemCrudLocal.cuil,
      email: itemCrudLocal.email,
      genero: itemCrudLocal.genero,
      fechaNacimiento: itemCrudLocal.fechaNacimiento
        ? format(new Date(itemCrudLocal.fechaNacimiento), "yyyy/MM/dd")
        : null,
      telefonoMovil: itemCrudLocal.telefonoMovil,
      estadoUltimo: itemCrudLocal.estadoUltimo,
      fechaEstadoUltimo: itemCrudLocal.fechaEstadoUltimo
        ? format(new Date(itemCrudLocal.fechaEstadoUltimo), "yyyy/MM/dd")
        : null,
      codigoExterno: itemCrudLocal.codigoExterno,
      origenDatos: itemCrudLocal.origenDatos,
      usernameUsuarioActualiza: localStorage.getItem("emailUsuario"),
      fotoPerfil: fotoPerfil,
    };
    if (actionCrud !== "create") {
      _itemCrud.id = itemCrudLocal.id;
    }
    setIsDoingSomething(true);
    const response = await saveUsuario(actionCrud, _itemCrud);
    setIsDoingSomething(false);

    if (response.ok) {
      onSave();
    }

    setMessage({
      messageText: response.messageText,
      severity: response.messageSeverity,
    });
    setShowMessage(true);
  };

  return (
    <>
      <Dialog
        open={true}
        onClose={handleCloseDialog}
        maxWidth="md"
        fullWidth={true}
        scroll="paper"
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title"
					textAlign={{ xs: "center", sm: "center" }}			
					style={{ background: darkTheme.palette.primary.dark, color: darkTheme.palette.primary.text}}
				>
          {actionCrud === "create"
            ? itemGender === "M"
              ? "Nuevo"
              : "Nueva"
            : actionCrud === "update"
            ? "Editando"
            : actionCrud === "enable"
            ? "Habilitando"
            : actionCrud === "read"
            ? "Detalle"
            : "Deshabilitando"}{" "}
          {nameItemText}
          <IconButton
            aria-label="close"
            onClick={handleCloseDialog}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>

        {isDoingSomething && <LinearProgress />}

        <DialogContent dividers>
				{fotoPerfil && (
            <Box sx={{ pb: 1, textAlign: "center" }}>
              <img
                src={fotoPerfil}
                alt="Foto de perfil"
                style={{
                  maxWidth: "100px",
                  maxHeight: "100px",
                  margin: "auto",
                }}
              />
            </Box>
          )}

          {!fotoPerfil &&
            (actionCrud === "read" || actionCrud === "update") &&
            itemCrudLocal.fotoPerfil && (
              <Box sx={{ pb: 1, textAlign: "center" }}>
                <img
                  src={itemCrudLocal.fotoPerfil}
                  alt="Foto de perfil"
                  style={{
                    maxWidth: "100px",
                    maxHeight: "100px",
                    margin: "auto",
                  }}
                />
              </Box>
            )}
          <Box
            sx={{
              pb: 1,
              display: "grid",
              gap: 1,
              gridTemplateColumns: "repeat(4, auto)",
              textAlign: "center",
							justifyContent: "center"
            }}
          >
            <input
              accept="image/*"
              style={{ display: "none" }}
              id="foto-perfil-input"
              type="file"
              onChange={handleFileChange}
            />
            {actionCrud === "create" && (
              <label htmlFor="foto-perfil-input">
                <Button variant="contained" component="span" startIcon={<AddAPhotoIcon />}>
                  Subir Foto de Perfil
                </Button>
              </label>
            )}
            {actionCrud === "update" && (
              <>
                <label>
                  <></>
                </label>
                <label htmlFor="foto-perfil-input">
                  <Button
                    variant="contained"
                    component="span"
                    sx={{ mt: 1, width: "100%" }}
										startIcon={<AddAPhotoIcon />}
									>
                    Cambiar Foto
                  </Button>
                </label>


                <label>
									<Button 
										variant="outlined"
										component="span"
										onClick={handleRemovePhoto}
										style={{
											mt: 1,
											ml: 1,
											width: "100%",
										}}
										sx={{ mt: 1 }}
										startIcon={<DeleteIcon />}
									>
										Eliminar Foto
									</Button>
                </label>
              </>
            )}
          </Box>

          <Box sx={{ m: 1, p: 1 }}>
            <TextField
              variant="outlined"
              size="small"
              name="tipoDocumento"
              label="Tipo de Documento"
              value={itemCrudLocal.tipoDocumento}
              onChange={handleChange}
              disabled={
                actionCrud === "disable" ||
                actionCrud === "enable" ||
                actionCrud === "read"
              }
              sx={{ width: "100%", pb: 1 }}
              inputProps={{ maxLength: 80 }}
            />

            <TextField
              variant="outlined"
              size="small"
              name="nroDocumento"
              label="Número de Documento"
              value={itemCrudLocal.nroDocumento}
              onChange={handleChange}
              disabled={
                actionCrud === "disable" ||
                actionCrud === "enable" ||
                actionCrud === "read"
              }
              sx={{ width: "100%", pb: 1 }} 
              inputProps={{ maxLength: 20 }}
            />

            <Box display="flex" alignItems="center">
              
              <Typography variant="h8" component="div" sx={{ pr: 1 }}>
                Sexo registrado al nacer:
              </Typography>
              <RadioGroup
                row
                name="sexoRegistradoAlNacer"
                value={itemCrudLocal.sexoRegistradoAlNacer}
                onChange={handleChange}
              >
                {sexoRegistradoAlNacerOptions.map((option) => (
                  <FormControlLabel
                    key={option.value}
                    value={option.value}
                    control={<Radio disabled={actionCrud === "disable" || actionCrud === "enable" || actionCrud === "read"} />}
                    label={option.label}
                  />
                ))}
              </RadioGroup>
            </Box>
            
            <TextField
              variant="outlined"
              size="small"
              name="nombre"
              label="Nombre"
              value={itemCrudLocal.nombre}
              onChange={handleChange}
              disabled={
                actionCrud === "disable" ||
                actionCrud === "enable" ||
                actionCrud === "read"
              }
              sx={{ width: "100%", pb: 1 }}
              autoFocus={true}
              inputProps={{ maxLength: 80 }}
            />

            <TextField
              variant="outlined"
              size="small"
              name="apellido"
              label="Apellido"
              value={itemCrudLocal.apellido}
              onChange={handleChange}
              disabled={
                actionCrud === "disable" ||
                actionCrud === "enable" ||
                actionCrud === "read"
              }
              sx={{ width: "100%", pb: 1 }}
              inputProps={{ maxLength: 80 }}
            />

            <TextField
              variant="outlined"
              size="small"
              name="username"
              label="Nombre de usuario (formato de e-mail)"
              value={itemCrudLocal.username}
              onChange={handleChange}
              disabled={
                actionCrud === "disable" ||
                actionCrud === "enable" ||
                actionCrud === "read"
              }
              sx={{ width: "100%", pb: 1 }}
              inputProps={{ maxLength: 80 }}
            />

            <TextField
              variant="outlined"
              size="small"
              name="legajo"
              label="Legajo"
              value={itemCrudLocal.legajo}
              onChange={handleChange}
              disabled={
                actionCrud === "disable" ||
                actionCrud === "enable" ||
                actionCrud === "read"
              }
              sx={{ width: "100%", pb: 1 }}
              inputProps={{ maxLength: 80 }}
            />

            <TextField
              variant="outlined"
              size="small"
              name="matricula"
              label="Matrícula"
              value={itemCrudLocal.matricula}
              onChange={handleChange}
              disabled={
                actionCrud === "disable" ||
                actionCrud === "enable" ||
                actionCrud === "read"
              }
              sx={{ width: "100%", pb: 1 }}
              inputProps={{ maxLength: 80 }}
            />

            <TextField
              variant="outlined"
              size="small"
              name="cuil"
              label="Número de CUIL"
              value={itemCrudLocal.cuil}
              onChange={handleChange}
              disabled={
                actionCrud === "disable" ||
                actionCrud === "enable" ||
                actionCrud === "read"
              }
              sx={{ width: "100%", pb: 1 }}
              inputProps={{ maxLength: 80 }}
            />

            <TextField
              variant="outlined"
              size="small"
              name="email"
              label="E-Mail"
              value={itemCrudLocal.email}
              onChange={handleChange}
              disabled={
                actionCrud === "disable" ||
                actionCrud === "enable" ||
                actionCrud === "read"
              }
              sx={{ width: "100%", pb: 1 }}
              inputProps={{ maxLength: 80 }}
            />

            <FormControl
              sx={{ width: "100%", pb: 1 }}
              disabled={
                actionCrud === "disable" ||
                actionCrud === "enable" ||
                actionCrud === "read"
              }
            >
              <InputLabel id="genero-select-label">Género</InputLabel>
              <Select
                sx={{ width: "100%", mr: 1 }}
                labelId="genero"
                label="Género"
                id="genero"
                name="genero"
                value={itemCrudLocal.genero}
                size="small"
                onChange={handleChange}
              >
                {generoOptions.map((item) => (
                  <MenuItem key={item.id} value={item.id}>
                    {item.descripcion}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <Box sx={{ pb: 1 }}>
              <DatePicker
                id="fechaNacimiento"
                label="Fecha de Nacimiento"
                variant="outlined"
                name="fechaNacimiento"
                value={itemCrudLocal.fechaNacimiento}
                onChange={(fechaNacimiento) =>
                  handleChangeFechaNacimiento(fechaNacimiento)
                }
                renderInput={(params) => <TextField size="small" {...params} />}
                inputFormat="dd/MM/yyyy"
                maxDate={new Date()}
                disabled={
                  actionCrud === "disable" ||
                  actionCrud === "enable" ||
                  actionCrud === "read"
                }
              />
            </Box>

            <TextField
              variant="outlined"
              size="small"
              name="telefonoMovil"
              label="Teléfono Móvil"
              value={itemCrudLocal.telefonoMovil}
              onChange={handleChange}
              disabled={
                actionCrud === "disable" ||
                actionCrud === "enable" ||
                actionCrud === "read"
              }
              sx={{ width: "100%", pb: 1 }}
              inputProps={{ maxLength: 80 }}
            />

            <TextField
              variant="outlined"
              size="small"
              name="estadoUltimo"
              label="Estado actual"
              value={itemCrudLocal.estadoUltimo}
              onChange={handleChange}
              disabled={
                actionCrud === "disable" ||
                actionCrud === "enable" ||
                actionCrud === "read"
              }
              sx={{ width: "100%", pb: 1 }}
              inputProps={{ maxLength: 80 }}
            />

            <Box sx={{ pb: 1 }}>
              <DatePicker
                id="fechaEstadoUltimo"
                label="Fecha de estado actual"
                variant="outlined"
                name="fechaEstadoUltimo"
                value={itemCrudLocal.fechaEstadoUltimo}
                onChange={(fechaEstadoUltimo) =>
                  handleChangeFechaEstadoUltimo(fechaEstadoUltimo)
                }
                renderInput={(params) => <TextField size="small" {...params} />}
                inputFormat="dd/MM/yyyy"
                maxDate={new Date()}
                disabled={
                  actionCrud === "disable" ||
                  actionCrud === "enable" ||
                  actionCrud === "read"
                }
              />
            </Box>

            <TextField
              variant="outlined"
              size="small"
              name="codigoExterno"
              label="Código Externo"
              value={itemCrudLocal.codigoExterno}
              onChange={handleChange}
              disabled={
                actionCrud === "disable" ||
                actionCrud === "enable" ||
                actionCrud === "read"
              }
              sx={{ width: "100%", pb: 1 }}
              inputProps={{ maxLength: 20 }}
            />

            <FormControl
              sx={{ width: "100%", pb: 1 }}
              disabled={
                actionCrud === "disable" ||
                actionCrud === "enable" ||
                actionCrud === "read"
              }
            >
              <InputLabel id="origen-datos-select-label">
                Origen de Datos
              </InputLabel>
              <Select
                sx={{ width: "100%", mr: 1 }}
                labelId="origenDatos"
                label="Origen de Datos"
                id="origenDatos"
                name="origenDatos"
                value={itemCrudLocal.origenDatos}
                size="small"
                onChange={handleChange}
              >
                {origenDatosOptions.map((item) => (
                  <MenuItem key={item.id} value={item.id}>
                    {item.descripcion}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            {actionCrud !== "create" && (
              <Box
                sx={{
                  p: 1,
                  mt: 1,
                  color: "SlateGrey",
                  border: "0px solid DarkGray",
                  borderRadius: "3px",
                }}
              >
                <Typography variant="h8" component="div" gutterBottom>
                  Creado: {itemCrudLocal.fechaCreacion}
                </Typography>

                <Typography variant="h8" component="div" gutterBottom>
                  Última actualización: {itemCrudLocal.fechaActualizacion}
                </Typography>

                <Typography variant="h8" component="div" gutterBottom>
                  Último usuario que actualizó: {itemCrudLocal.nombreUsuario}{" "}
                  {itemCrudLocal.apellidoUsuario} (
                  {itemCrudLocal.username_actualiza})
                </Typography>
              </Box>
            )}
          </Box>

          <Box sx={{ textAlign: "right" }}>
            <Button
              sx={{ mr: 1 }}
              variant="outlined"
              onClick={handleCloseDialog}
							startIcon={<CloseIcon />}
            >
              Cancelar
            </Button>

            {actionCrud !== "read" && (
              <Button
                sx={{ mr: 1, backgroundcolor: "warning" }}
                variant="contained"
                onClick={handleSave}
                startIcon={
                  actionCrud === "disable" ? (
                    <RemoveCircleOutlineIcon />
                  ) : actionCrud === "enable" ? (
                    <AddCircleOutlineIcon />
                  ) : (
                    <SaveIcon />
                  )
                }
              >
                {actionCrud === "disable"
                  ? "Deshabilitar"
                  : actionCrud === "enable"
                  ? "Habilitar"
                  : "Guardar"}
              </Button>
            )}
          </Box>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default UsuarioDialog;
